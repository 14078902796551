import '@vime/core/themes/default.css';
import dynamic from 'next/dynamic';
import { PropTypes } from 'prop-types';
import getVideoId from 'get-video-id';

function MediaPlayer({ url, mode }) {
    // Issue with Vime missing an audio module when loaded in NextJs, dynamic imports resolve it.
    // See https://github.com/vime-js/vime/issues/231
    const Player = dynamic(() => import('@vime/react').then((module) => module.Player), { ssr: false });
    const Youtube = dynamic(() => import('@vime/react').then((module) => module.Youtube), { ssr: false });
    const Vimeo = dynamic(() => import('@vime/react').then((module) => module.Vimeo), { ssr: false });
    const Audio = dynamic(() => import('@vime/react').then((module) => module.Audio), { ssr: false });

    function renderAudioPlayer(url) {
        return (
            <Audio>
                <source data-src={url} type="audio/mp3" />
            </Audio>
        );
    }

    function vimeoProperUrl(videoId, url) {
        let urlSplit = url.split('vimeo.com/');
        urlSplit = urlSplit[1].split('/');
        if (urlSplit.length > 1) {
            return `${videoId}?h=${urlSplit[1]}`;
        }
        return `${videoId}`;
    }

    function renderVideoPlayer(url) {
        const type = getVideoId(url).service;
        const videoId = getVideoId(url).id;

        return (
            <>
                {type === 'youtube' && <Youtube videoId={videoId} />}
                {type === 'vimeo' && <Vimeo videoId={vimeoProperUrl(videoId, url)} />}
            </>
        );
    }

    return (
        <Player controls>
            {mode === 'video' && renderVideoPlayer(url)}
            {mode === 'audio' && renderAudioPlayer(url)}
        </Player>
    );
}

MediaPlayer.propTypes = {
    mode: PropTypes.oneOf(['video', 'audio']),
    url: PropTypes.string,
};

export default MediaPlayer;
