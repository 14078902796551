const langColors = {
  EN: 'sky',
  TR: 'peach'
}

function LanguagePill(props) {
  return (
    <span className={`text-black inline-block px-[0.5em] text-[12px] leading-[1.5] tracking-widest uppercase rounded-md bg-${langColors[props.lang]}`}>{props.lang}</span>
  );
}

export default LanguagePill;