import React from 'react';
import AudioBlock from './blocks/AudioBlock';
import DownloadBlock from './blocks/DownloadBlock';
import GalleryBlock from './blocks/GalleryBlock';
import TextBlock from './blocks/TextBlock';
import VideoBlock from './blocks/VideoBlock';
import CommentBlock from './blocks/CommentBlock';
import DoubleContentBlock from './blocks/DoubleContentBlock';
import DividerHeadingBlock from './blocks/DividerHeadingBlock';
import ResourcesBlock from './blocks/ResourcesBlock';
import StepsBlock from './blocks/StepsBlock';
import TicketTapeBlock from './blocks/TicketTapeBlock';
import VideoContentBlock from './blocks/VideoContentBlock';
import FeaturesTapeBlock from './blocks/FeaturesTapeBlock';
import SignUpBlock from './blocks/SignUpBlock';

// Map our components to wagtail blocks
const blocksMap = {
    Audio: AudioBlock,
    Download: DownloadBlock,
    Gallery: GalleryBlock,
    text: TextBlock,
    Video: VideoBlock,
    Comments: CommentBlock,
    DoubleContentBox: DoubleContentBlock,
    DividerHeading: DividerHeadingBlock,
    Resources: ResourcesBlock,
    Steps: StepsBlock,
    VideoContent: VideoContentBlock,
    TicketTape: TicketTapeBlock,
    FeaturesTape: FeaturesTapeBlock,
    SignUp: SignUpBlock,
};

/**
 * Render out a bunch of components from a source array, e.g. a streamfield
 */
function BlockContent({ blocks, parentPage = false }) {
    return (
        <>
            {blocks.map((block) => {
                return React.createElement(blocksMap[block.type], { ...block, parentPage: parentPage, key: block.id });
            })}
        </>
    );
}

export default BlockContent;
