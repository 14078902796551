import { Sticker } from '@/components/Stickers';
import Button from '@/components/Button/Button';

function SignUpBlock(props) {
    const {
        value: { heading, buttonText, buttonUrl, stickerColour, stickerImage, stickerColour2, stickerImage2 },
    } = props;
    return (
        <section className="relative flex flex-col items-center justify-center px-8 py-32 text-center rounded-2xl">
            <Sticker name={stickerImage2} color={stickerColour2} className="absolute w-[80px] md:w-[150px] top-[5%] right-[5%] rotate-12 z-10" />
            <Sticker name={stickerImage} color={stickerColour} className="absolute w-[80px] md:w-[150px] bottom-[5%] left-[5%] -rotate-12 z-10" />
            <h2 className="relative z-20 mb-7 display">{heading}</h2>
            <Button href={buttonUrl} element="a">
                {buttonText}
            </Button>
        </section>
    );
}

export default SignUpBlock;
