import Button from '@/components/Button/Button';
import { Sticker } from '@/components/Stickers';
import { useState } from 'react';
    

function DoubleContentBlock(props) {
    return (
        <section className={`grid grid-cols-2 block-spacing  ${props.value.contentBox.video ? 'py-12' : ''}`} >
            {RenderContentBox(props.value.contentBox, props.parentPage)}
            {RenderContentBox(props.value.contentBox2, props.parentPage)}
        </section>
    );
}

function RenderContentBox(content, parentPage) {
    const [showVideo, setShowVideo] = useState(false);
    return (
        <>
            {/* This is a giant ternary - sorry world.  */}
            {!!content.title || !!content.content ? (
                <div
                    className="relative flex flex-col items-start col-span-2 p-4 py-8 md:p-8 md:py-12 rounded-2xl lg:col-span-1"
                    style={{ backgroundColor: `var(--color-${content.bgColour || 'cream'})` }}>
                    {!!content.stickerImage && <Sticker name={content.stickerImage} color={content.stickerColour} className="mb-8 w-28 md:w-36" />}
                    {!!content.title && <h2 className={'mb-8 ' + (parentPage == 'home' ? 'display' : 'h1')}>{content.title}</h2>}

                    {!!content.subtitle && (
                        <h3 className="mb-2 text-base leading-5 align-baseline -translate-y-3 md:-translate-y-4 sm:text-lg lg:text-home-copy">
                            {content.subtitle}
                        </h3>
                    )}

                    {!!content.content && <div className="mb-8" dangerouslySetInnerHTML={{ __html: content.content }}></div>}
                        <div className="flex gap-4">
                        {!!content.linkText && !!content.linkUrl.length && (
                            <Button element="a" href={content.linkUrl[0].value} className="mt-auto">
                                {content.linkText}
                            </Button>
                        )}
                        {!!content.linkText2 && !!content.linkUrl2.length && (
                            <Button element='a' href={content.linkUrl2[0].value} className="mt-auto" type='secondary'>
                                {content.linkText2}
                            </Button>
                        )}
                        </div>
                </div>
            ) : (
                <article
                    className="flex items-center justify-center col-span-2 p-8 rounded-2xl lg:col-span-1"
                    style={{ backgroundColor: `var(--color-${content.bgColour || 'cream'})` }}>
                    <Sticker name={content.stickerImage} color={content.stickerColour} className="w-[50%]" />
                </article>
            )}
        </>
    );
}

export default DoubleContentBlock;
