import Button from '@/components/Button/Button';
import { ArrowCircleDownIcon } from '@heroicons/react/outline';
import { ArrowDownIcon } from '@heroicons/react/solid';

const DownloadTile = function ({fileUrl, thumbnail, fileName, fileSize}) {
  return (
    <article className="relative flex items-center p-3 space-x-3 border rounded-lg border-granite">
        <a href={fileUrl} download className="flex items-center justify-center flex-shrink-0 w-20 h-20 border-2 border-transparent rounded-md bg-granite hover:border-mango transition-border">
          { thumbnail ? 
            <img className="object-cover w-20 h-20 border-0" src={thumbnail || ''} alt={fileName} />
            : <ArrowCircleDownIcon className="w-8 text-white"/>
          }
        </a>
        <div className="flex-1 min-w-0">
            <a href={fileUrl} download>
                <p className="mb-3">{fileName}</p>
                <Button type="secondary" icon={<ArrowDownIcon />} size="small">Download{fileSize && ` (${fileSize})`}</Button>
            </a>
        </div>
    </article>
  );
}

export default DownloadTile