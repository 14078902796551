const tailwindConfig = require('../../../tailwind.config.js');
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';
import { useState } from 'react';
import useFontFaceObserver from 'use-font-face-observer';
import { Sticker } from '@/components/Stickers';

function renderTicket(ticket, index, ticketsLength) {
    return <>
        <div className='flex gap-4 px-6 py-3 bg-cream rounded-2xl'>
        {ticket.displayName}
        </div>

        {((index % 3) % 3 === 0) && (
            <div className='flex'>
                <h2 className='h3 flex'>Thanks a million!
                    <Sticker name='heart' color='terracotta' height='8px' width='8px' className="h-[20px] pl-2" />
                    </h2>
            </div>
        )}
    </>
}

function TicketTapeBlock(props) {
    const { value: { tickets }} = props;
    
    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    const isFontListLoaded = useFontFaceObserver([{family: 'flexa'}]);
    const ticketsLength = tickets.length - 1;
    return (
        <div className=' bg-sky py-6 rounded-2xl'>
            <PageVisibility onChange={handleVisibilityChange}>
                {pageIsVisible && isFontListLoaded && (
                    <Ticker>
                        {({ index }) => (
                            <>
                            <div className='flex gap-6 mr-6 whitespace-nowrap items-center'>
                                {tickets.map((ticket, index) => {
                                    return (
                                            renderTicket(ticket, index, ticketsLength)
                                            // {if ((index === tickets.length -1) || ((index  / 3)%3 === 0)) {
                                            //     <div>somehring</div>
                                            // }}
                                        
                                    )
                                })}
                        </div>
                        </>
                            
                        
                        )}
                    </Ticker>
                )}
            </PageVisibility>
        </div>
    )
}
  
  export default TicketTapeBlock;