import Button from '@/components/Button/Button';
import { Sticker } from '@/components/Stickers';

function StepsBlock(props) {
    // console.dir(props);
    const {
        value: { image, stickerImage, stickerColour, stickerBg, step },
    } = props;
    return (
        <section className="grid grid-cols-4 auto-rows-min md:grid-rows-2 block-spacing">
            {/* Feature image/sticker */}
            <article
                className="flex items-center justify-center col-span-4 overflow-hidden md:col-span-2 md:row-span-2 rounded-2xl"
                style={{ backgroundColor: `var(--color-${stickerBg || 'cream'})` }}>
                {!!image?.file && (
                    <img
                        src={image.renditions.medium.src}
                        alt={image.renditions.medium.alt}
                        className="object-cover object-center w-full h-full rounded-2xl"
                    />
                )}
                {!!stickerImage && !image?.file.length && <Sticker name={stickerImage} color={stickerColour} className="w-[50%]" />}
            </article>

            {/* Steps (max three) */}
            {step.map((step, index) => renderStep(step, index))}
        </section>
    );
}

function renderStep(step, index) {
    // console.dir(step)
    return (
        <article
            key={index + step.title}
            className={`flex flex-col h-full col-span-4 p-4 py-8 md:p-8 md:py-12 bg-granite rounded-2xl ${
                index == 2 ? 'col-span-4 xl:col-span-2' : 'md:col-span-2 xl:col-span-1'
            }`}
            style={{ backgroundColor: `var(--color-${step.bgColour || 'granite'})` }}>
            <h3 className="mb-4 display">{step.title}</h3>
            <div className="max-w-md mt-auto text-home-copy" dangerouslySetInnerHTML={{ __html: step.content }}></div>
            {!!step.linkText && !!step.linkUrl.length && (
                <Button element="a" href={step.linkUrl[0].value} className="mt-6 w-min md:w-max">
                    {step.linkText}
                </Button>
            )}
        </article>
    );
}

export default StepsBlock;
