import { useEffect } from 'react';
import Kete1 from '../Stickers/KeteStrandOne';
import Kete2 from '../Stickers/KeteStrandTwo';
import Kete3 from '../Stickers/KeteStrandThree';
import Kete from '../Stickers/Kete';

export default function RenderStrand({ strand, className }) {
    if (!strand) return;
    switch (strand.icon) {
        case 1:
            return (
                <>
                    <Kete1 className={className} color={strand.colour} />
                </>
            );
        case 2:
            return (
                <>
                    <Kete2 className={className} color={strand.colour} />
                </>
            );
        case 3:
            return (
                <>
                    <Kete3 className={className} color={strand.colour} />
                </>
            );
        default:
            return (
                <>
                    <Kete className={className} color="cream" />
                </>
            );
    }
}
