import MediaPlayer from '@/components/MediaPlayer/MediaPlayer';

function VideoContentBlock(props) {
    const {
        value: { heading, video, content },
    } = props;
    return (
        <section className="grid gap-8 p-4 md:p-8 md:grid-cols-2 rounded-2xl bg-granite">
            <div className="w-full aspect-video">
                <MediaPlayer url={video.videoUrl} mode="video" />
            </div>
            <div>
                <h2 className="mb-4 h1">{heading}</h2>
                {!!content && <div className="mb-8 text-large-copy" dangerouslySetInnerHTML={{ __html: content }}></div>}
            </div>
        </section>
    );
}

export default VideoContentBlock;
