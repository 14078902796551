const tailwindConfig = require('../../../tailwind.config.js');
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';
import { useState } from 'react';
import useFontFaceObserver from 'use-font-face-observer';

function renderFeature(feature, index) {
    return (
        <div className="flex gap-4 uppercase" key={index}>
            {feature}{' '}
            <svg width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg" className="relative top-[2px]">
                <path
                    d="M17.1624 10.8667C16.6231 10.5711 15.4834 10.3995 14.2526 10.3007C14.1115 10.2894 14.1111 10.0912 14.2522 10.0794C15.4826 9.97643 16.6217 9.80092 17.1599 9.50344C18.6369 8.68723 19.1449 6.87606 18.2947 5.45818C17.4444 4.0403 15.5579 3.55253 14.081 4.36874C13.5145 4.6818 12.7356 5.61508 11.9905 6.64678C11.9096 6.75887 11.7301 6.65922 11.7909 6.53594C12.3505 5.4013 12.8044 4.2876 12.8044 3.66012C12.8044 2.02404 11.4229 0.697754 9.71871 0.697754C8.01454 0.697754 6.63303 2.02404 6.63303 3.66012C6.63303 4.30259 7.10887 5.45477 7.68682 6.61726C7.74811 6.74051 7.56844 6.84065 7.48737 6.72847C6.72238 5.67022 5.91608 4.70223 5.33491 4.38368C3.85496 3.57248 1.97025 4.06665 1.12528 5.48746C0.280313 6.90826 0.795065 8.71764 2.27501 9.52885C2.81437 9.82448 3.95402 9.9961 5.18482 10.0948C5.32595 10.1061 5.32632 10.3044 5.18522 10.3162C3.95481 10.4191 2.8158 10.5946 2.27754 10.8921C0.800605 11.7083 0.292555 13.5195 1.14278 14.9374C1.99301 16.3552 3.87954 16.843 5.35644 16.0268C5.92289 15.7137 6.70181 14.7804 7.44688 13.7487C7.52784 13.6366 7.70736 13.7363 7.64656 13.8596C7.08694 14.9942 6.63304 16.1079 6.63304 16.7354C6.63304 18.3715 8.01455 19.6977 9.71872 19.6977C11.4229 19.6977 12.8044 18.3715 12.8044 16.7354C12.8044 16.0929 12.3286 14.9407 11.7506 13.7782C11.6893 13.655 11.869 13.5549 11.9501 13.667C12.7151 14.7253 13.5214 15.6933 14.1025 16.0118C15.5825 16.823 17.4672 16.3288 18.3122 14.908C19.1571 13.4872 18.6424 11.6779 17.1624 10.8667Z"
                    fill={tailwindConfig.theme.extend.colors[Object.keys(tailwindConfig.theme.extend.colors)[index]]}
                />
            </svg>
        </div>
    );
}

function FeaturesTapeBlock(props) {
    const {
        value: { features },
    } = props;

    const [pageIsVisible, setPageIsVisible] = useState(true);

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible);
    };

    const isFontListLoaded = useFontFaceObserver([{ family: 'flexa' }]);

    return (
        <div className="my-8">
            <PageVisibility onChange={handleVisibilityChange}>
                {pageIsVisible && isFontListLoaded && (
                    <Ticker>
                        {({ index }) => (
                            <>
                                <div className="flex gap-4 mr-4">
                                    {features.map((feature, index) => {
                                        return renderFeature(feature, index);
                                    })}
                                </div>
                            </>
                        )}
                    </Ticker>
                )}
            </PageVisibility>
        </div>
    );
}

export default FeaturesTapeBlock;
