import RenderStrand from './RenderStrand';
const tailwindConfig = require('../../tailwind.config.js');
const notTheseColors = ['offwhite', 'cream', 'contentScrollbar'];
const colors = Object.keys(tailwindConfig.theme.extend.colors).filter((color) => !notTheseColors.includes(color));
import { useEffect } from 'react';

function Strand({ strands }) {
    return (
        <>
            {!!strands && strands.length > 0 && !!strands[0].strand && (
                <span className="flex items-center gap-2 text-small-copy">
                    <RenderStrand strand={strands[0].strand} color={strands[0].strand.colour} className={`w-5`} />
                    {strands[0].strand.name}
                </span>
            )}
        </>
    );
}

export default Strand;
