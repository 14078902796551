import React from 'react';
import Button from '@/components/Button/Button';
import Link from 'next/link';
import LanguagePill from '@/components/LanguagePill/LanguagePill';
import { HeartIcon } from '@heroicons/react/solid';
import Strand from '@/components/Strand/Strand';
import { stickerMap } from '@/components/Stickers';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toggleFavouriteResource } from '../../store/user/userSlice';

function resolveMainResourceType(resource) {
    if (!resource.body || !resource.body.length > 0) return;

    const resourceMedia = resource.body.filter((item) => {
        return ['Audio', 'Video', 'Download'].includes(item.type);
    });

    if (resourceMedia.length) {
        return resourceMedia[0].type;
    }

    return 'article';
}

function ResourceTile(props) {
    const { resource, showLinks } = props;
    const resourceType = resolveMainResourceType(resource);
    const favourite = useSelector((state) => state.user.favouriteResources.indexOf(resource.id) > -1);

    const dispatch = useDispatch();
    const resourceLink = showLinks ? resource.url : '/account/sign-up/';
    function handleToggleFavourite(resource) {
        dispatch(toggleFavouriteResource(resource.id));
    }

    return (
        <article className="flex flex-col gap-4 pb-4 border-b-2 border-heather/40">
            <Link href={resourceLink} className="justify-self-start">
                <a className="flex flex-col group">
                    <div className="flex items-center gap-x-4">
                        <figure
                            className="flex flex-shrink-0 items-center overflow-hidden justify-center w-20 h-20 rounded-md transition-all shadow-none group-hover:-translate-y-0.5 group-hover:shadow-lg"
                            style={{ backgroundColor: `var(--color-${resource.stickerBg ? resource.stickerBg : 'granite'})` }}>
                            {resource.image?.file && <img src={resource.image?.url} className="object-cover min-w-full min-h-full" />}
                            {Object.keys(stickerMap).includes(resource.stickerImage) && !resource.image?.file && (
                                <div className="transition-all group-hover:animate-wiggle">
                                    {React.createElement(stickerMap[resource.stickerImage], {
                                        className: 'w-16 transition-all drop-shadow-none group-hover:drop-shadow-lg',
                                        color: resource.stickerColor,
                                    })}
                                </div>
                            )}
                        </figure>

                        <div className="pb-1">
                            <h3 className="mb-1">{resource.title}</h3>
                            <div className="flex flex-wrap items-center gap-3">
                                <Strand strands={resource.strands} />
                                {resource.languages && (
                                    <ul className="inline-flex items-center gap-1">
                                        {resource.languages.map((lang) => (
                                            <React.Fragment key={lang.language.code}>
                                                {lang?.language && (
                                                    <li>
                                                        <LanguagePill lang={lang.language.code} />
                                                    </li>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                )}
                                {/* Might enable these again some time?
                  <span>{resource.duration}</span> 
                  { renderMediaType(resourceType) }
                */}
                            </div>
                        </div>
                    </div>
                </a>
            </Link>

            {!!resource.previewText && (
                <div className="max-w-md mb-2 text-small-copy text-heather">
                    <p>{resource.previewText}</p>
                </div>
            )}
            <div className="flex items-center gap-3 mt-auto h-max">
                <Button element="a" href={resourceLink} type="secondary">
                    {showLinks && <>Learn more</>} {!showLinks && <>Sign up to learn more</>}
                </Button>
                {showLinks && (
                    <button onClick={(e) => handleToggleFavourite(resource)} className={favourite ? 'text-sunset' : 'text-heather'}>
                        <HeartIcon className="w-6 transition-transform hover:scale-110 transform-gpu" />
                    </button>
                )}
            </div>
        </article>
    );
}

export default ResourceTile;
