import { Sticker } from '@/components/Stickers';

function DividerHeadingBlock(props) {
    const {
        value: { stickerImage, stickerColour, heading },
    } = props;

    return (
        <section className="rounded-2xl px-8 py-20 mt-12 bg-[url('/img/bg-weave-light-grey.svg')] bg-[length:220px_auto] flex items-center justify-center text-center relative">
            {!!stickerImage && (
                <Sticker name={stickerImage} color={stickerColour} className="absolute w-[80px] md:w-[110px] -top-[15%] right-[5%] rotate-12 z-10" />
            )}
            <h2 className="relative z-20 display">{heading}</h2>
        </section>
    );
}

export default DividerHeadingBlock;
