import MediaPlayer from '@/components/MediaPlayer/MediaPlayer';

function VideoBlock(props) {
  const {value: {title, videoUrl, caption} } = props;

  return (
    <div className="mb-16">
      <h3 className="mb-4 text-xl">{title}</h3>
      <div className="w-full rounded-lg aspect-video bg-granite">
          <MediaPlayer url={videoUrl} mode="video" />
      </div>
      <div className="mt-4 text-sm">{caption}</div>
    </div>
  );
}

export default VideoBlock;