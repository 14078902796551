import MediaPlayer from "@/components/MediaPlayer/MediaPlayer";

function AudioBlock({value: {title, audioFile}}) {
  return (
    <div className="px-8 py-6 border rounded-md border-granite">
      <p className="mb-4 text-xl">{title}</p>
      <MediaPlayer mode="audio" url={audioFile.file}/>
    </div>
  );
}

export default AudioBlock;