import { classNames } from '@/utils/classNames';
import Image from 'next/image'

function GalleryBlock({value: {galleryImages}}) {
  if(!galleryImages.length) return;
  
  return (
    <div className="grid grid-cols-2 gap-6 mb-16">
      { galleryImages.map((img, index) => {
        return (
          <figure className={classNames('rounded-md overflow-hidden', index == 0 || galleryImages.length == 2 ? 'col-span-2' : '')}
            key={img.file} >
            <img
              className='object-cover'
              layout='responsive'
              alt={img.title}
              src={img.file}
              width={800} height={600} />
            </figure>
        )
      }) }
    </div>
  );
}

export default GalleryBlock;