import DownloadTile from "@/components/DownloadTile/DownloadTile";
import { formatFileSize } from '@/utils/formatFileSize';

function DownloadBlock({value: {title, previewThumbnail, fileName, fileSize, download}}) {
  return (
    <DownloadTile
      thumbnail={previewThumbnail.file}
      fileName={fileName}
      fileUrl={download[0].value.file}
      fileSize={formatFileSize(fileSize)}
    />
  );
}

export default DownloadBlock;