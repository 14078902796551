import ResourcesList from '@/components/ResourcesList/ResourcesList';

function ResourcesBlock(props) {
    const { value: { resources } } = props;
    return (
        <section className="">
            <ResourcesList resources={resources} title='Preview Resources' showButton={false} showLinks={false}/>
        </section>
    );
  }
  
  export default ResourcesBlock;