import { classNames } from "../../utils/classNames";

function GroupHeader(props) {
  return (
    <div className={classNames(props.className, 'flex items-center justify-between')}>
      <h2>{props.title}</h2>
      <div className="flex items-center gap-4">
        {props.children}
      </div>
    </div>
  );
}

export default GroupHeader;