import { useEffect } from 'react';
import ResourceTile from '@/components/ResourceTile/ResourceTile';
import GroupHeader from '@/components/GroupHeader/GroupHeader';
import Button from '@/components/Button/Button';
import slugify from 'slugify';

function normalisedResources(resourcesList) {
    const listIsArray = Object.prototype.toString.call(resourcesList) == '[object Array]';
    if (!resourcesList || !listIsArray || resourcesList.length < 1) return [];

    const normalisedResources = resourcesList.reduce((collection, item) => {
        return collection.concat({ ...item, ...item.built, meta: item.meta });
    }, []);

    return normalisedResources;
}

function ResourcesList({ title = 'Resources', resources, showButton = true, showLinks = true }) {
    const cleanedResources = normalisedResources(resources);
    // Watch show button for changes
    useEffect(() => {}, [showButton]);

    return (
        <>
            <GroupHeader title={title} className="mb-6">
                {showButton && (
                    <Button href="/search/?search_group=resources" type="secondary" size="small" element="a">
                        See All
                    </Button>
                )}
            </GroupHeader>

            <ul className="grid grid-cols-1 gap-y-8 md:gap-y-16 gap-x-6 md:grid-cols-2">
                {cleanedResources.length > 0 &&
                    cleanedResources.map((resource) => {
                        return (
                            <li className="grid" key={`${resource.id}-${resource.title}`}>
                                <ResourceTile resource={resource} showLinks={showLinks} />
                            </li>
                        );
                    })}
            </ul>
        </>
    );
}

export default ResourcesList;
